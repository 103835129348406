import { getYoutubeId } from './utils/youtube';
import Modal from '../../node_modules/bootstrap/js/dist/modal';

const articleVideos = document.querySelectorAll('[data-yt-video]');
/**
 * Render topstory thumbnail
 */
if (articleVideos) {
    articleVideos.forEach((articleVideo) => {
        const url = articleVideo.getAttribute('data-yt-video');
        const id = getYoutubeId(url);
        if (id) {
            articleVideo.querySelector('.article-video-image').setAttribute('src', `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`);

            const button = articleVideo.querySelector('.article-play-video-button');
            button.setAttribute('data-video', id);

            button.addEventListener('click', () => {
                const videoModal = document.createElement('div');
                videoModal.id = `modal${id}`;
                videoModal.innerHTML = `
                    <div id="videoModal${id}" class="modal" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered modal-xl">
                            <div class="modal-content">
                                <div class="modal-header border-0">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Zavřít"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="ratio ratio-16x9">
                                        <iframe width="100%" height="644" data-src="https://www.youtube.com/embed/${id}?autoplay=1" data-cookiecategory='social' data-placeholder class='youtube-modal-video' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
                document.body.append(videoModal);
                const modal = new Modal(`#videoModal${id}`);
                modal.show();

                let cc_cookie = getCookie('cc_cookie');
                if (cc_cookie) {
                    cc_cookie = JSON.parse(cc_cookie);
                    if (!cc_cookie.level.includes('social')) {
                        document.querySelectorAll('[data-src][data-cookiecategory="social"][data-placeholder]').forEach(function (el) {
                            el.src = '/dist/cookie-consent-frame.html';
                        });
                    } else {
                        const video = document.querySelector('.youtube-modal-video');
                        video.src = video.dataset.src;
                    }
                } else {
                    document.querySelectorAll('[data-src][data-cookiecategory="social"][data-placeholder]').forEach(function (el) {
                        el.src = '/dist/cookie-consent-frame.html';
                    });
                }

                const modalToRemove = document.querySelector(`#modal${id}`);
                modalToRemove.addEventListener('hidden.bs.modal', () => {
                    videoModal.remove();
                });
            });
        }
    });
}

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
